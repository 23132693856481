import Axios from 'axios'
import * as Sentry from '@sentry/vue'

const baseURL = import.meta.env.VITE_APP_API

const axios = Axios.create({
  baseURL,
  timeout: 20000
})

// Intercept before sending HTTP requests
axios.interceptors.request.use(
  (response) => {
    /**
     * you can process config here
     */
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Intercept after received HTTP requests
axios.interceptors.response.use(
  (response) => {
    /**
     * you can process response and error here
     */
    return response
  },
  (error) => {
    if (Axios.isAxiosError(error)) {
      const response = error?.response

      if (response) {
        // The request was made and the server responded with a status code
        Sentry.captureException(error, {
          extra: {
            response: error.toJSON()
          }
        })
      }
    }
    return Promise.reject(error)
  }
)

export default axios
