import routes from './routes'
import useFacebook from '@/composables/facebook'

import { createRouter, createWebHistory } from 'vue-router'
import { useTheme } from '@/composables/theme'
import { event } from 'vue-gtag'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(async (routeTo, routeFrom, next) => {
  const authStore = useAuthStore()
  const { onTrack } = useFacebook()
  const { validateCharity } = useTheme()
  const { loggedIn } = storeToRefs(authStore)

  try {
    await validateCharity()
  } catch (e) {
    const error = typeof e === 'string' ? new Error(e) : e
    return next(error)
  }

  function redirectToLogin() {
    // Pass the original route to the login component
    let redirectFrom = routeTo.fullPath
    return next({
      name: 'auth.login',
      query: { redirectFrom },
      params: routeTo.params
    })
  }

  onTrack('ViewContent')
  event('screen_view', {
    app_name: 'Donor WebApp v4.0',
    screen_name: routeTo.name
  })

  if (
    loggedIn.value &&
    ['auth.login', 'auth.signup', 'auth.forgot-password'].includes(routeTo.name)
  ) {
    return next({ name: 'account.profile', params: routeTo.params })
  }
  // Check if auth is required on this route
  // (including nested routes).// Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)
  // If auth isn't required for the route, just continue.
  if (!authRequired) return next()

  if (loggedIn.value) {
    return next()
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  redirectToLogin()
})

router.onError((error) => {
  alert(error)
})

export default router
