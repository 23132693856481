// import { createNewEvent } from '@/utils/helper'
import HSTomSelect from '../assets/front-v4.2/js/hs.tom-select'

export default {
  mounted: (el) => {
    HSTomSelect.init(el)
    // s2.on('select2:select', (e) => {
    //   e.target.dispatchEvent(createNewEvent('change'));
    // });
  }
}
