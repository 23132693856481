const authChildRoutes = (prop, mode = false) => [
  {
    path: 'login',
    name: `${prop}.login`,
    meta: {
      authRequired: mode,
      title: ''
    },
    component: () => import('../views/pages/auth/login.vue')
  },
  {
    path: 'signup',
    name: `${prop}.signup`,
    meta: {
      authRequired: mode,
      title: ''
    },
    component: () => import('../views/pages/auth/signup.vue')
  },
  {
    path: 'forgot-password',
    name: `${prop}.forgot-password`,
    meta: {
      authRequired: mode,
      title: ''
    },
    component: () => import('../views/pages/auth/forgot-password.vue')
  },
  {
    path: 'reset-password',
    name: `${prop}.reset-password`,
    meta: {
      authRequired: mode,
      title: 'Reset Password'
    },
    component: () => import('../views/pages/auth/reset-password.vue')
  }
]

export default authChildRoutes
