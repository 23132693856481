import axios from '@/utils/axios'

import { defineStore, storeToRefs } from 'pinia'
import { getSavedState, clearAuth, saveState } from '@/utils/helper'
import { useNotificationStore } from './notification'
import { useDonorStore } from './donor'
import { useCharityStore } from '@/stores/charity'
import { useEventStore } from '@/stores/event'
import * as Sentry from '@sentry/vue'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    accessToken: getSavedState('auth.Token'),
    activeCharityId: null,
    adminInfo: null,
    loginModalRef: null,
    signUpFromCheckout: !!getSavedState('auth.signUpFromCheckout')
  }),
  getters: {
    loggedIn() {
      return !!this.getVerifiedTokenClaims
    },
    viewAsDonor() {
      return this.getVerifiedTokenClaims?.proxy
    },
    getLoginModalRef(state) {
      return state.loginModalRef
    },
    isSignUpFromCheckout(state) {
      return state.signUpFromCheckout
    },
    getToken(state) {
      return state.accessToken
    },
    /**
     * Parse the claims from the access token and return them with no additional checks.
     * @returns {object|null}
     */
    getTokenClaims() {
      const token = this.getToken
      if (!token) {
        return null
      }

      const tokenParts = token.split('.')
      return JSON.parse(window.atob(tokenParts[1]))
    },
    /**
     * Parse the claims from the access token, validate them, and log out if the token is invalid.
     * @returns {object|null}
     */
    getVerifiedTokenClaims() {
      const claims = this.getTokenClaims
      if (!claims) {
        return null
      }

      const now = Math.floor(Date.now() / 1000)

      if (claims.exp && claims.exp < now) {
        this.logOut()
        return null
      }

      return claims
    }
  },
  actions: {
    setSignUpFromCheckout(newVal) {
      this.signUpFromCheckout = newVal
      saveState('auth.signUpFromCheckout', this.signUpFromCheckout)
    },
    setToken(token) {
      this.accessToken = token
      saveState('auth.Token', token)
    },
    setLoginModalRef(loginRef) {
      this.loginModalRef = loginRef
    },
    async login({ email, password, domain }) {
      const formData = new FormData()
      formData.append('email', email)
      formData.append('password', password)
      formData.append('domain', domain)

      return axios
        .post('/login/email', formData)
        .then(async (Response) => {
          const charity = useCharityStore()
          const { getCharityInfo } = storeToRefs(charity)
          const res = Response.data
          this.setToken(res.token)

          if (getCharityInfo.value?.charity_id) {
            const eventStore = useEventStore()
            await eventStore.fetchStoreInfo(getCharityInfo.value.charity_id)

            try {
              const { getStore } = storeToRefs(eventStore)
              if (getStore.value) {
                setTimeout(() => {
                  eventStore.loginCustomer(getStore.value.id)
                }, 300)
              }
            } catch (error) {}
          }

          return res
        })
        .catch((Error) => {
          const notification = useNotificationStore()
          notification.getNotificationError(Error)
          return Promise.reject(notification.getNotification?.message || 'Error')
        })
    },
    async signup({
      email,
      password,
      firstName,
      lastName,
      countryCode,
      phoneNumber,
      charityId,
      recaptchaToken
    }) {
      const formData = new FormData()
      formData.append('charity_id', charityId)
      formData.append('email', email)
      formData.append('password', password)
      formData.append('first_name', firstName)
      formData.append('last_name', lastName)
      formData.append('recaptcha_token', recaptchaToken)

      if (phoneNumber) {
        formData.append('country_code', countryCode)
        formData.append('phone_number', phoneNumber)
      }

      return axios
        .post('/signup', formData)
        .then(async (Response) => {
          const res = Response.data
          this.setToken(res.token)

          const charity = useCharityStore()
          const { getCharityInfo } = storeToRefs(charity)

          if (getCharityInfo.value?.charity_id) {
            const eventStore = useEventStore()
            await eventStore.fetchStoreInfo(getCharityInfo.value.charity_id)

            try {
              const { getStore } = storeToRefs(eventStore)
              if (getStore.value) {
                await eventStore.loginCustomer(getStore.value.id)
              }
            } catch (error) {}
          }

          return Response.data
        })
        .catch((Error) => {
          const notification = useNotificationStore()
          notification.getNotificationError(Error)
          return Promise.reject(notification.getNotification?.message || 'Error')
        })
    },
    logOut() {
      clearAuth()
      Sentry.configureScope((scope) => {
        scope.clear()
        scope.setUser(null)
      })
      const donor = useDonorStore()
      this.accessToken = null
      donor.setDonorDetail(null)
      this.setSignUpFromCheckout(true)

      const eventStore = useEventStore()
      eventStore.logOutCustomer()
    },
    async resetPassword({ email, charity_id }) {
      const formData = new FormData()
      formData.append('email', email)
      formData.append('charity_id', charity_id)

      return axios
        .post('/account/password/reset', formData)
        .then((Response) => {
          return Response.data
        })
        .catch((Error) => {
          const notification = useNotificationStore()
          notification.getNotificationError(Error)
          return Promise.reject(notification.getNotification?.message || 'Error')
        })
    },
    async recoverPassword({ token, password }) {
      const formData = new FormData()
      formData.append('token', token)
      formData.append('password', password)

      return axios
        .post('/account/password', formData)
        .then((Response) => {
          return Response.data
        })
        .catch((Error) => {
          const notification = useNotificationStore()
          notification.getNotificationError(Error)
          return Promise.reject(notification.getNotification?.message || 'Error')
        })
    }
  }
})
