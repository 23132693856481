import axios from '@/utils/axios'
import useGTag from '@/composables/gtag'
import useFacebook from '@/composables/facebook'

import { defineStore } from 'pinia'
import { getSavedState, saveState } from '@/utils/helper'
import { useNotificationStore } from './notification'

export const useCharityStore = defineStore('charity', {
  state: () => ({
    charity_info: getSavedState('charity.Info')
  }),
  getters: {
    getCharityInfo(state) {
      return state.charity_info
    },
    getCharityId() {
      return this.getCharityInfo?.charity_id
    }
  },
  actions: {
    async getWhiteLabel(domain) {
      const { initGTag } = useGTag()
      const { initPixel } = useFacebook()

      return axios
        .get(`/whitelabel/${domain}/config`)
        .then((Response) => {
          if (!(Response.headers['content-type'] || '').startsWith('application/json')) {
            throw new Error('Received unexpected response while trying to load configuration.')
          }

          this.setCharityInfo(Response.data)
          if (Response.data.addons?.facebook_pixel) {
            initPixel(Response.data.addons?.facebook_pixel)
          }

          if (Response.data.addons?.google_tag_manager) {
            initGTag(Response.data.addons?.google_tag_manager)
          }
          return Response.data
        })
        .catch((Error) => {
          const notification = useNotificationStore()
          notification.getNotificationError(Error)
          return Promise.reject(notification.getNotification.message)
        })
    },
    setCharityInfo(data) {
      this.charity_info = data
      saveState('charity.Info', data)
    }
  }
})
