import * as Sentry from '@sentry/vue'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createHead } from '@unhead/vue'
import { VueCookieNext } from 'vue-cookie-next'
import { plugin as VueTippy } from 'vue-tippy'
import { LDPlugin } from 'launchdarkly-vue-client-sdk'
import { BrowserTracing } from '@sentry/tracing'

import 'bootstrap'
import '@stripe/stripe-js'

import HSCore from './assets/front-v4.2/js/hs.core'

HSCore.init()

import App from './App.vue'

import router from './router'
import filters from './utils/filters'
import tippyOptions from './utils/tippy'

import 'tippy.js/dist/tippy.css'
import '@/assets/scss/app.scss'

import maskedInput from './directives/masked-input'
import select2Custom from './directives/select2'
import rangeSlider from './directives/range-slider'
import cleave from './directives/cleave'
import { useCharityStore } from '@/stores/charity'
import { AxiosError } from 'axios'

VueCookieNext.config({
  expire: '30d',
  path: '/',
  secure: true,
  sameSite: import.meta.env.VITE_APP_TITLE
})

const pinia = createPinia()

const app = createApp(App)
  .use(pinia)
  .use(createHead())
  .use(VueCookieNext)
  .use(VueTippy, tippyOptions)

app.config.globalProperties.$filters = filters

if (import.meta.env.VITE_SENTRY_DSN) {
  // Sentry release constant defined in `vite.config.js`.
  const definedReleaseId =
    // eslint-disable-next-line no-undef
    typeof __HARNESS_SENTRY_RELEASE__ === 'string' ? __HARNESS_SENTRY_RELEASE__ : null

  // Attempt to parse the sample rate value, if one is configured.
  const parsedSampleRate =
    import.meta.env.VITE_SENTRY_SAMPLE_RATE !== undefined
      ? parseFloat(import.meta.env.VITE_SENTRY_SAMPLE_RATE)
      : null

  // Use the configured value if one is present and it is valid, otherwise default to 100%.
  const sampleRate =
    parsedSampleRate !== null &&
    !isNaN(parsedSampleRate) &&
    parsedSampleRate >= 0.0 &&
    parsedSampleRate <= 1.0
      ? parsedSampleRate
      : 1.0

  // List of User-Agents for which we do not want to send any Sentry events.
  const blockedUserAgents = ['HeadlessChrome', 'prerender/prerender']

  // For a given `event` (or transaction), check if the User-Agent is one which is blocked.
  const isBlockedUserAgent = (event) => {
    return (
      blockedUserAgents.findIndex((userAgent) => {
        return (
          window.navigator.userAgent.search(userAgent) !== -1 ||
          (event?.request?.headers && event.request.headers['User-Agent'].search(userAgent) !== -1)
        )
      }) !== -1
    )
  }

  const getCharityId = () => {
    try {
      const charityStore = useCharityStore()
      return charityStore.getCharityId
    } catch (error) {
      console.error(error)
      return null
    }
  }

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        // tracePropagationTargets: ['localhost', import.meta.env.VITE_URI, /^\//]
      })
    ],
    // The percentage of error events to record. 1.0 is 100% of events, 0.1 is 10%, etc.
    sampleRate: sampleRate,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: sampleRate,
    // Sentry release constant defined in `vite.config.js`.
    // eslint-disable-next-line no-undef
    release: definedReleaseId || import.meta.env.VITE_SENTRY_RELEASE || undefined,
    environment:
      import.meta.env.VITE_SENTRY_ENVIRONMENT ||
      import.meta.env.VITE_APP_ENVIRONMENT ||
      'development',
    beforeSend(event, hint) {
      if (isBlockedUserAgent(event)) {
        return null
      }
      const exception = hint.originalException
      if (exception instanceof AxiosError) {
        const charityId = getCharityId()
        const fullURL = new URL(exception.config.url, exception.config.baseURL)
        const exceptionPath = charityId
          ? fullURL.pathname.replace(charityId, ':charityId')
          : fullURL.pathname

        event.fingerprint = [
          '{{ default }}',
          String(exceptionPath),
          String(exception.config.method)
        ]
      }

      return event
    },
    beforeSendTransaction(event) {
      if (isBlockedUserAgent(event)) {
        return null
      }
      return event
    },
    ignoreErrors: [
      // Error generated by Microsoft SafeLinks crawler
      // See: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/20
      'Non-Error promise rejection captured with value: Object Not Found Matching Id:'
    ]
  })
}

app.use(router)
app.use(LDPlugin, { clientSideID: import.meta.env.VITE_APP_LAUNCHDARKLY_ID })
app.directive('imask', maskedInput)
app.directive('range-slider', rangeSlider)
app.directive('select2', select2Custom)
app.directive('cleave', cleave)

router.isReady().then(() => {
  app.mount('#app')
})
