import moment from 'moment-timezone'

import { useCharityStore } from '@/stores/charity'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'

const filters = {
  stripHTML(string) {
    const div = document.createElement('div')
    div.innerHTML = string
    const text = div.textContent || div.innerText || ''
    return text
  },
  formatMoney(val, n, x) {
    if (val % 1 !== 0 && n < 2) {
      n = 2
    }
    let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')'
    return parseFloat(val).toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,')
  },
  formatInteger(val) {
    return Intl.NumberFormat().format(val)
  },
  correctLink(link) {
    if (link.split('//').length < 2) {
      return '//' + link
    }
    return link
  },
  newWebAppLink(link, charityInfo) {
    if (!charityInfo) {
      return null
    }

    return `${import.meta.env.VITE_SCHEME}://${charityInfo.subdomain}.${
      import.meta.env.VITE_URI_NEW
    }${link}`
  },
  dateFormat(date, format, fromFormat = false) {
    if (date === null) {
      return moment().format(format)
    }
    if (fromFormat) {
      return moment(date, fromFormat).format(format)
    }
    return moment(date).format(format)
  },
  dateFormatTz(date, format, fromTz) {
    fromTz = fromTz ? fromTz : 'UTC'

    if (date) {
      const currentDate = moment.tz(date, fromTz)
      const zoneName = moment.tz.guess()
      const convertedDate = currentDate.clone().tz(zoneName)
      return convertedDate.format(format)
    }
    return 'N/A'
  },
  hostFromLink(link) {
    const a = document.createElement('a')
    a.href = link
    return (a && a.hostname) || link
  },
  campaignName(campaignId, allCampaigns) {
    campaignId = parseInt(campaignId) || 0
    const selectedCmp = allCampaigns.reduce(
      (res, cmp) => (parseInt(cmp.id) === campaignId ? cmp : res),
      null
    )
    return selectedCmp ? selectedCmp.name : 'Area of Greatest Need'
  },
  formatPhoneNumber(str) {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '')

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }

    return null
  },
  webAppLinkAuth(link) {
    const charityStore = useCharityStore()
    const authStore = useAuthStore()

    const { getCharityInfo } = storeToRefs(charityStore)
    const { getToken } = storeToRefs(authStore)

    if (getCharityInfo.value) {
      return `${import.meta.env.VITE_SCHEME}://${getCharityInfo.value.subdomain}.${
        import.meta.env.VITE_URI_NEW
      }/donate/embedded-api/?proxyToken=${getToken.value}&redirectFrom=${link}`
    }

    return ''
  },
  urlDonate() {
    const charityStore = useCharityStore()

    const { getCharityInfo } = storeToRefs(charityStore)
    return `${import.meta.env.VITE_SCHEME}://${getCharityInfo.value.subdomain}.${
      import.meta.env.VITE_URI_NEW
    }/donate`
  }
}

export default filters
