import HSNoUISlider from '@/assets/front-v4.2/js/hs.nouislider'
import 'nouislider/dist/nouislider.min.css'

const dataDefault = {
  range: {
    min: 10,
    max: 100
  },
  connect: [true, false],
  start: 30,
  pips: {
    mode: 'count',
    values: 5,
    density: 4,
    stepped: true
  },
  showTooltips: true,
  tooltip: {
    prefix: '$'
  }
}

const createElement = (el, binding) => {
  HSNoUISlider.init(el, {
    ...dataDefault,
    ...binding.value
  })
}

export default {
  mounted: (el, binding) => {
    createElement(el, binding)
  }
}
