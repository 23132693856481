<script setup>
import useLaunchDarkly from '@/composables/launch-darkly'

import { computed, onMounted } from 'vue'
import { useCharityStore } from '@/stores/charity'
import { storeToRefs } from 'pinia'

const charityStore = useCharityStore()

const { getCharityInfo } = storeToRefs(charityStore)
const { updateLaunchDarklyContext, registerLaunchDarklyWatcher, setupLaunchDarkly } =
  useLaunchDarkly()

const siteName = computed(() => {
  return getCharityInfo.value ? getCharityInfo.value.charity_name : ''
})

registerLaunchDarklyWatcher()

onMounted(() => {
  setupLaunchDarkly()

  // Update user context if charity data is loaded and available
  if (getCharityInfo.value) {
    updateLaunchDarklyContext()
  }

  window.addEventListener('beforeunload', () => {
    charityStore.setCharityInfo(null)
  })
})
</script>

<template>
  <iframe name="hidden-iframe" style="visibility: hidden; position: absolute"></iframe>

  <metainfo>
    <template #title="{ content }">
      {{ content ? `${content} | ${siteName}` : siteName }}
    </template>
  </metainfo>

  <router-view />
</template>

<style lang="scss">
/* route transition */
.route-enter-from {
  opacity: 0;
  transform: translateX(-100px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}
.route-leave-to {
  opacity: 0;
  transform: translateX(100px);
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(100px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}
</style>
