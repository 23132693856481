import { setOptions, bootstrap } from 'vue-gtag'

export default function () {
  const initGTag = (googleTagManager) => {
    setOptions({
      config: { id: googleTagManager }
    })

    bootstrap().then(() => {
      // all done!
    })
  }

  return {
    initGTag
  }
}
