import { hex2rgb } from '@csstools/convert-colors'
import { useCharityStore } from '@/stores/charity'
import { storeToRefs } from 'pinia'

export function useTheme() {
  const charityStore = useCharityStore()

  const { getCharityInfo } = storeToRefs(charityStore)

  const validateCharity = async () => {
    // todo: add try catch
    if (!getCharityInfo.value) {
      await charityStore.getWhiteLabel(getDomain())
    }

    setColors()
  }

  const getDomain = () => {
    const subdomain = window.location.host.split('.')[0]

    if (subdomain?.search('localhost') !== -1) {
      // return 'clay';
      // return '6f3305e7cfca67f60006';
      // return 'cmn';
      // return 'bsa';
      // return 'directrelief';
      // return '5c516fe7b5c853e0a620';//Equality California
      // return 'alsa';
      // return 'harp';
      // return 'lander';
      return 'beatnb'
      //return 'clay'
      // return 'sager'
      // return 'academyatthelakes';//without campaigns
      // return 'helpingpeople';
      // return 'dream';
    }
    if (window.location.hostname?.search('127.0.0.1') !== -1) {
      return 'beatnb'
    }
    if (subdomain?.search('192') !== -1) {
      return 'clay'
    }
    if (subdomain === 'webapp-staging-3') {
      return 'clay'
    }
    // TODO: just for cloudflare-pages
    if (subdomain?.search('pages.dev') !== -1) {
      return 'beatnb'
    }
    if (subdomain.search('donation-forms-v4') !== -1) {
      return 'beatnb'
    }

    return subdomain
  }

  const setColors = () => {
    if (getCharityInfo.value) {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
      link.type = 'image/png'
      link.rel = 'shortcut icon'
      link.href = getCharityInfo.value.logo_square || getCharityInfo.value.logo_transparent

      const root = document.querySelector(':root')

      let prColor = getCharityInfo.value.primary_color
      let sdColor = getCharityInfo.value.secondary_color

      let [pRed, pGreen, pBlue] = hex2rgb(prColor)
        .map((c) => (c / 100) * 255)
        .map(Math.round)
      let [sRed, sGreen, sBlue] = hex2rgb(sdColor)
        .map((c) => (c / 100) * 255)
        .map(Math.round)

      root.style.setProperty('--primary', prColor)
      root.style.setProperty('--primary-opacity', `${prColor}1a`)
      root.style.setProperty('--secondary', sdColor)

      root.style.setProperty(`--primary0075`, `rgba(${pRed}, ${pGreen}, ${pBlue}, 0.075)`)
      root.style.setProperty(`--secondary0075`, `rgba(${sRed}, ${sGreen}, ${sBlue}, 0.075)`)

      for (let i = 1; i <= 9; i++) {
        root.style.setProperty(`--primary0${i}`, `rgba(${pRed}, ${pGreen}, ${pBlue}, 0.${i})`)
        root.style.setProperty(`--secondary0${i}`, `rgba(${sRed}, ${sGreen}, ${sBlue}, 0.${i})`)
      }
    }
  }

  return {
    validateCharity,
    getDomain
  }
}
