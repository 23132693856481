const accountChildRoutes = (prop, mode = false) => [
  {
    path: '',
    redirect: '/account/profile'
  },
  {
    path: 'profile',
    name: `${prop}.profile`,
    meta: {
      authRequired: mode,
      title: 'Profile',
      page: 'Profile'
    },
    component: () => import('../views/pages/account/profile.vue')
  },
  {
    path: 'donations',
    name: `${prop}.donation`,
    meta: {
      authRequired: mode,
      title: 'Donation History',
      page: 'Donations'
    },
    component: () => import('../views/pages/account/donation.vue')
  },
  {
    path: 'billing',
    name: `${prop}.billing`,
    meta: {
      authRequired: mode,
      title: 'Billing',
      page: 'Billing'
    },
    component: () => import('../views/pages/account/billing.vue')
  },
  {
    path: 'round-ups-card/:id',
    name: `${prop}.round-ups-transaction`,
    meta: {
      authRequired: mode,
      title: 'Round-Ups',
      page: 'Round-Ups'
    },
    component: () => import('../views/pages/account/round-ups-transaction.vue')
  },
  {
    path: 'round-ups',
    name: `${prop}.round-up`,
    meta: {
      authRequired: mode,
      title: 'Round-Ups',
      page: 'Round-Ups'
    },
    component: () => import('../views/pages/account/round-up.vue')
  },
  {
    path: 'recurring',
    name: `${prop}.recurring`,
    meta: {
      authRequired: mode,
      title: 'Recurring',
      page: 'Recurring'
    },
    component: () => import('../views/pages/account/recurring.vue')
  },
  {
    path: 'tickets',
    name: `${prop}.ticket`,
    meta: {
      authRequired: mode,
      title: 'Tickets',
      page: 'Tickets'
    },
    component: () => import('../views/pages/account/ticket.vue')
  }
]

export default accountChildRoutes
