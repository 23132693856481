// https://github.com/nosir/cleave.js/blob/master/doc/vue.md
import Cleave from 'cleave.js'
import('cleave.js/dist/addons/cleave-phone.us.js')
import('cleave.js/dist/addons/cleave-phone.ca.js')

export default {
  mounted(el, binding) {
    el.cleave = new Cleave(el, binding.value || {})
  },
  updated(el) {
    const event = new Event('input', { bubbles: true })
    setTimeout(function () {
      el.value = el.cleave.properties.result
      el.dispatchEvent(event)
    }, 100)
    // //setTimeout(() => {
    // if (el.value && el.value !== el.value.trim()) {
    //   const event = new Event('input', { bubbles: true });
    //   el.value = el.cleave.getRawValue();
    //   el.value = el.cleave.properties.result;

    //   // el.dispatchEvent(event);
    // }
    // //}, 100);
  }
}
