/* eslint-disable */
const impactChildRoutes = (prop, mode = false) => [
  {
    path: '/donation-calculator',
    name: `${prop}.index`,
    meta: {
      authRequired: mode,
      title: 'Donation Calculator',
      withFooter: false,
      withHeader: true
    },
    component: () => import('../views/pages/impact/donation-calculator.vue')
  },
  {
    path: '/impact-amounts',
    name: `${prop}.cart`,
    meta: {
      authRequired: mode,
      title: 'Impact Amount',
      withFooter: true,
      withHeader: true
    },
    component: () => import('../views/pages/impact/impact-amount.vue')
  },
  {
    path: '/impact-amounts/:impactId',
    name: `${prop}.impact-amounts.detail`,
    meta: {
      authRequired: mode,
      title: 'Impact Amount Detail',
      withFooter: false,
      withHeader: true
    },
    component: () => import('../views/pages/impact/impact-amount-detail.vue')
  },
  {
    path: '/impact-calculator',
    name: `${prop}.calculator`,
    component: () => import('../views/pages/impact/impact-calculator.vue'),
    meta: {
      title: 'Impact Calculator',
      withFooter: false,
      withHeader: true
    }
  }
]

export default impactChildRoutes
