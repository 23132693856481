import * as Sentry from '@sentry/vue'
import toString from 'lodash/toString'
import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    type: null,
    message: null,
    status: null
  }),
  getters: {
    getNotification(state) {
      return {
        type: state.type,
        message: state.message
      }
    }
  },
  actions: {
    getNotificationError(Error) {
      Sentry.captureException(Error)

      this.status = Error?.response?.status || 'Unknown'
      // Get the "main" message associated with the error response
      const getMainMessage = (Error) =>
        toStringAddPeriods(Error.response?.data?.error || Error.response?.data?.message || null)

      // If there is an array of multiple error messages, extract them from the Error.
      const getErrorMessages = (Error) => {
        let messages =
          Error.response?.data?.reasons ||
          Error.response?.data?.messages ||
          Error.response?.data?.errors ||
          []

        // Remove an uninformative "bad request" message, if present
        if (
          messages.length > 1 &&
          typeof messages[0] === 'string' &&
          messages[0].toLocaleLowerCase() === 'bad request'
        ) {
          messages.shift()
        }

        return messages
      }

      // Since we're just going to join all of the messages together with a space,
      // then we'll make sure all of the messages end with a period so they're
      // easy to visually differentiate and read.
      const toStringAddPeriods = (message) => {
        if (!message) {
          return null
        }
        let msg = toString(message).trim()
        if (!msg.endsWith('.') && !msg.endsWith('!') && !msg.endsWith('?')) {
          msg += '.'
        }
        return msg
      }

      // If an array of error messages was provided, merge them all into a single string.
      const getFullErrorMessageString = (Error) => {
        let mainMessage = getMainMessage(Error)
        let messages = getErrorMessages(Error)

        // Return null if we found no messages at all
        if (mainMessage === null && (messages === null || messages.length === 0)) {
          return null
        }

        // Add the main message to the other messages if it's not "bad request" (or if there are no other messages)
        if (
          mainMessage &&
          (messages.length == 0 || mainMessage.toLocaleLowerCase() !== 'bad request.')
        ) {
          messages.unshift(mainMessage)
        }

        return messages.map(toStringAddPeriods).join(' ')
      }

      // If no messages were found in the response, fallback to stringifying whatever does exist
      const getFallbackErrorMessage = (Error) => {
        if (Error.response) {
          if (Error.response?.data) {
            // Don't try to stringify an HTML error page.
            if (
              Error.response?.headers['content-type'] &&
              Error.response?.headers['content-type']?.toLocaleLowerCase()?.startsWith('text/html')
            ) {
              return 'Server Error ' + (Error.response.status || 'Unknown')
            }

            return toString(Object.values(Error.response.data))
          }
          return 'Server Error ' + (Error.response?.status || 'Unknown')
        } else {
          return 'Error: ' + (Error.message || 'An unexpected error has occurred')
        }
      }

      let message = getFullErrorMessageString(Error) || getFallbackErrorMessage(Error)

      this.error(message)
    },
    clear() {
      this.type = null
      this.message = null
    },
    success(message) {
      this.message = message
      this.type = 'success'

      setTimeout(() => {
        this.clear()
      }, 2200)
    },
    error(message) {
      this.message = message
      this.type = 'danger'

      setTimeout(() => {
        this.clear()
      }, 2200)
    }
  }
})
