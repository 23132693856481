const eventChildRoutes = (prop, mode = false) => [
  {
    path: '',
    name: `${prop}.list`,
    meta: {
      authRequired: mode,
      title: 'Events'
    },
    component: () => import('../views/pages/event/list.vue')
  },
  {
    path: ':eventId',
    name: `${prop}.detail`,
    meta: {
      authRequired: mode,
      title: ''
    },
    component: () => import('../views/pages/event/detail.vue')
  }
]

export default eventChildRoutes
