import HSMask from '@/assets/front-v4.2/js/hs.imask'

export default {
  mounted: (el) => {
    HSMask.init(el)
  },
  updated: (el) => {
    if (el.value && el.value !== el.value.trim()) {
      HSMask.init(el)
    }
  }
}
