export default function () {
  const initPixel = (facebookPixel) => {
    if (!document.getElementById('fb-pixel') && !window.fbq) {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        t.id = 'fb-pixel'
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')

      if (window.fbq) {
        /* eslint-disable-next-line */
        fbq('init', facebookPixel)
        /* eslint-disable-next-line */
        fbq('track', 'PageView')
      }

      const noscript = document.createElement('noscript')
      const img = document.createElement('img')
      img.setAttribute(
        'src',
        `https://www.facebook.com/tr?id=${facebookPixel}&ev=PageView&noscript=1`
      )
      img.setAttribute('height', '1')
      img.setAttribute('width', '1')
      img.setAttribute('style', 'display: none')
      noscript.appendChild(img)
      document.body.appendChild(noscript)
    }
  }

  const onTrack = (evt, opt = null) => {
    if (window.fbq) {
      /* eslint-disable-next-line */
      if (!opt) {
        /* eslint-disable-next-line */
        fbq('track', evt)
      } else {
        /* eslint-disable-next-line */
        fbq('track', evt, opt)
      }
    }
  }
  return {
    initPixel,
    onTrack
  }
}
