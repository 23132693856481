import _ from 'lodash'
import axios from './axios'
import moment from 'moment'

import { VueCookieNext } from 'vue-cookie-next'
import { hex2rgb } from '@csstools/convert-colors'
import { useAuthStore } from '@/stores/auth'
import { useTheme } from '@/composables/theme'

/**
 * get harness_id for JWT
 * @returns {string}
 */
export function getHarnessId() {
  const authStore = useAuthStore()
  return authStore.getVerifiedTokenClaims?.harness_id || null
}
/* Private helpers */
export const getSavedState = (key) => {
  const { getDomain } = useTheme()
  const domain = getDomain()

  if (!domain) return

  const parsedKey = `${key}---${domain}`
  const expiresAt = localStorage.getItem(`cache-${parsedKey}`)

  if (expiresAt && expired(expiresAt)) {
    localStorage.removeItem(`cache-${parsedKey}`)
    localStorage.removeItem(parsedKey)
    VueCookieNext.removeCookie(parsedKey)
    return null
  }

  if (localStorage.getItem(parsedKey) != null && localStorage.getItem(parsedKey) !== 'undefined') {
    return JSON.parse(localStorage.getItem(parsedKey))
  }

  return VueCookieNext.getCookie(parsedKey)
}

//expiresAt is expired if it is set and in the past
function expired(expiresAt) {
  return expiresAt && moment().isSameOrAfter(expiresAt)
}

export const saveState = (key, state, expiresAt) => {
  const { getDomain } = useTheme()
  const domain = getDomain()

  if (!domain) return

  const parsedKey = `${key}---${domain}`

  if (expiresAt) {
    localStorage.setItem(`cache-${parsedKey}`, expiresAt.format())
  }

  localStorage.setItem(parsedKey, JSON.stringify(state))
}

export const clearAuth = () => {
  const { getDomain } = useTheme()
  const domain = getDomain()

  if (!domain) return

  removeDomainLocalStorageKeys(domain)
  removeDomainCookieKeys(domain)

  VueCookieNext.removeCookie('auth.Token')
  saveState('auth.ViewAsDonor', null)
}

export const getDefaultCampaign = (
  image,
  description = 'Donations will go to the area needed most'
) => {
  return {
    id: 0,
    name: 'Area of Greatest Need',
    description: description,
    image: image
  }
}

export const getAllCookies = () => VueCookieNext.keys()

export const removeDomainCookieKeys = (domain) => {
  const parsedDomain = `---${domain}`

  getAllCookies().forEach((cookie) => {
    if (cookie.includes(parsedDomain)) {
      VueCookieNext.removeCookie(cookie, {
        path: `/${domain}`
      })
    }
  })
}

export const createNewEvent = (eventName) => {
  let event
  if (typeof Event === 'function') {
    event = new Event(eventName)
  } else {
    event = document.createEvent('Event')
    event.initEvent(eventName, true, true)
  }
  return event
}

export const notificationError = (Error, dispatch) => {
  let errorMessage = null

  if (Error.response?.status === 500) {
    if (Error.response.data && Error.response.data.error) {
      errorMessage = Error.response.data.error
    } else {
      errorMessage = 'Server Error'
    }
  } else if (Error.response?.status === 400) {
    if (Error.response.data.messages) {
      let errorMessages = Error.response.data.messages

      if (Array.isArray(errorMessages)) {
        // If the `message` contains multiple messages,
        // then remove the first element if it just says "bad request".
        if (
          errorMessages.length > 1 &&
          typeof errorMessages[0] === 'string' &&
          errorMessages[0].toLocaleLowerCase() === 'bad request'
        ) {
          errorMessages.shift()
        }

        // Since we're just going to join all of the messages together with a space,
        // then we'll make sure all of the messages end with a period so they're
        // easy to visually differentiate and read.
        const toStringAddPeriods = (message) => {
          let msg = _.toString(message).trim()
          if (!msg.endsWith('.')) {
            msg += '.'
          }
          return msg
        }

        errorMessage = errorMessages.map(toStringAddPeriods).join(' ')
      } else {
        errorMessage = _.toString(errorMessages)
      }
    } else {
      const _items = _.map(Error.response.data, (item) => item)
      errorMessage = _.toString(_items)
    }
  } else if (Error.response?.data?.error) {
    errorMessage = Error.response.data.error
  }

  dispatch('notification/error', errorMessage, { root: true })

  return { message: errorMessage, status: Error.response?.status }
}

export const shorten = (str, maxLen, separator = ' ', end = '…') => {
  if (str.length <= maxLen) return str
  return str.substr(0, str.lastIndexOf(separator, maxLen)) + end
}

export const stripHtml = (html) => {
  let tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

export const setDefaultAuthHeaders = (state) => {
  axios.defaults.headers.common['X-Harness-JWT'] = state.accessToken ? `${state.accessToken}` : ''
}

export const setColors = (charity) => {
  if (charity) {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
    link.type = 'image/png'
    link.rel = 'shortcut icon'
    link.href = charity.logo_square || charity.logo_transparent

    const root = document.querySelector(':root')

    let prColor = charity.primary_color
    let sdColor = charity.secondary_color

    let [pRed, pGreen, pBlue] = hex2rgb(prColor)
      .map((c) => (c / 100) * 255)
      .map(Math.round)
    let [sRed, sGreen, sBlue] = hex2rgb(sdColor)
      .map((c) => (c / 100) * 255)
      .map(Math.round)

    root.style.setProperty('--primary', prColor)
    root.style.setProperty('--primary-opacity', `${prColor}1a`)
    root.style.setProperty('--secondary', sdColor)

    root.style.setProperty(`--primary0075`, `rgba(${pRed}, ${pGreen}, ${pBlue}, 0.075)`)
    root.style.setProperty(`--secondary0075`, `rgba(${sRed}, ${sGreen}, ${sBlue}, 0.075)`)

    for (let i = 1; i <= 9; i++) {
      root.style.setProperty(`--primary0${i}`, `rgba(${pRed}, ${pGreen}, ${pBlue}, 0.${i})`)
      root.style.setProperty(`--secondary0${i}`, `rgba(${sRed}, ${sGreen}, ${sBlue}, 0.${i})`)
    }
  }
}

export const isExpired = (month, year) => {
  if (parseInt(year) < new Date().getFullYear()) {
    return true
  }
  if (parseInt(year) === new Date().getFullYear()) {
    if (parseInt(month) < new Date().getMonth() + 1) {
      return true
    }
  }
  return false
}

export const getAllLocalStorageKeys = () => Object.keys(localStorage)

export const removeLocalStorageKeys = (keys) => {
  keys.forEach((key) => {
    localStorage.removeItem(key)
  })
}

export const removeDomainLocalStorageKeys = (domain) => {
  const parsedDomain = `---${domain}`

  const keys = getAllLocalStorageKeys()
  const filteredKeys = keys.filter((key) => key.includes(parsedDomain))

  removeLocalStorageKeys(filteredKeys)
}
