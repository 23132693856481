const campaignChildRoutes = (prop, mode = false) => [
  {
    path: '',
    name: `${prop}.list`,
    meta: {
      authRequired: mode,
      title: 'Campaigns'
    },
    component: () => import('../views/pages/campaign/list.vue')
  },
  {
    path: ':campaignId',
    name: `${prop}.detail`,
    meta: {
      authRequired: mode,
      title: ''
    },
    component: () => import('../views/pages/campaign/detail.vue')
  }
]

export default campaignChildRoutes
