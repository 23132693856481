import { ref, computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import { useCharityStore } from '@/stores/charity'
import { useDonorStore } from '@/stores/donor'
import { getHarnessId } from '@/utils/helper'

import { useLDClient } from 'launchdarkly-vue-client-sdk'

export default function () {
  const auth = useAuthStore()
  const charity = useCharityStore()
  const donor = useDonorStore()

  const { loggedIn } = storeToRefs(auth)
  const { getCharityInfo } = storeToRefs(charity)
  const { getDonorDetail } = storeToRefs(donor)

  const ldClient = ref(null)

  const userContext = computed(() => {
    const baseContext = {
      kind: 'user',
      anonymous: !loggedIn.value
    }

    if (!loggedIn.value) {
      return { ...baseContext }
    } else if (loggedIn.value && getDonorDetail.value) {
      return {
        ...baseContext,
        key: getHarnessId(),
        firstName: getDonorDetail.value.first_name || null,
        lastName: getDonorDetail.value.last_name || null,
        email: getDonorDetail.value.email || null
      }
    } else {
      return null
    }
  })

  const charityContext = computed(() => {
    return getCharityInfo.value
      ? {
          kind: 'charity',
          key: getCharityInfo.value.charity_id,
          name: getCharityInfo.value.charity_name,
          paymentsEnabled: getCharityInfo.value.payments_enabled,
          subdomain: getCharityInfo.value.subdomain
        }
      : null
  })

  // Keeping this around for the watcher, but right now the Vue launch darkly plugin
  // is using an old version of the LaunchDarkly SDK which does not support multi-context.
  const multiContext = computed(() => {
    return {
      kind: 'multi',
      user: userContext.value,
      charity: charityContext.value
    }
  })

  const updateLaunchDarklyContext = () => {
    // Don't do anything if the client hasn't been loaded yet.
    if (!ldClient.value) {
      return
    }

    // Do not identify if the user context is not ready yet.
    if (!multiContext.value.user) {
      return
    }

    ldClient.value.identify(multiContext.value)
  }

  const isInitialized = computed(() => !!ldClient.value)

  /**
   * Initialize this set up so we have access to the LaunchDarkly client.
   * This uses `inject()` so it must be called within a Vue `setup()`/`<script setup>`.
   */
  const setupLaunchDarkly = () => {
    ldClient.value = useLDClient()
  }

  /**
   * Set up a reactive `watch` to track if user information is updated,
   * so user context can be updated when it changes.
   */
  const registerLaunchDarklyWatcher = () => {
    watch(
      multiContext,
      (newContext) => {
        // If there is no charity info available, do not try to identify yet.
        if (!newContext?.charity) {
          return
        }

        // Do not identify if user data is not ready yet (ex: user logged in but details request hasn't finished)
        if (!newContext?.user) {
          return
        }

        // Make sure we've received the injected client
        if (!isInitialized.value) {
          return
        }

        updateLaunchDarklyContext()
      },
      { deep: true }
    )
  }

  return {
    userContext,
    charityContext,
    multiContext,
    registerLaunchDarklyWatcher,
    setupLaunchDarkly,
    updateLaunchDarklyContext
  }
}
