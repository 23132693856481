import authChildRoutes from './auth'
import accountChildRoutes from './account'
import postChildRoutes from './post'
import campaignChildRoutes from './campaign'
import eventChildRoutes from './event'
import checkoutChildRoutes from './checkout'
import impactChildRoutes from './impact'

export default [
  {
    path: '/',
    redirect: '/auth/login'
  },
  {
    path: '/auth',
    component: () => import('../views/layouts/auth.vue'),
    children: authChildRoutes('auth', false)
  },
  {
    path: '/account',
    component: () => import('../views/pages/account/index.vue'),
    children: accountChildRoutes('account', true)
  },
  {
    path: '/posts',
    component: () => import('../views/pages/post/index.vue'),
    children: postChildRoutes('post', false)
  },
  {
    path: '/campaigns',
    component: () => import('../views/pages/campaign/index.vue'),
    children: campaignChildRoutes('campaign', false)
  },
  {
    path: '/events',
    component: () => import('../views/pages/event/index.vue'),
    children: eventChildRoutes('event', false)
  },
  {
    path: '/receipt/:id',
    name: 'receipt',
    component: () => import('../views/pages/account/receipt.vue'),
    meta: {
      title: 'Receipt'
    }
  },
  {
    path: '/receipt/guest/:transactionId',
    name: 'receipt.guest',
    component: () => import('../views/pages/public/guest-receipt.vue'),
    meta: {
      authRequired: false,
      title: 'Receipt'
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/pages/cart/index.vue'),
    meta: {
      authRequired: false,
      title: 'Cart'
    }
  },
  {
    path: '/checkout',
    component: () => import('../views/pages/checkout/index.vue'),
    children: checkoutChildRoutes('checkout', false)
  },
  {
    path: '/impact',
    component: () => import('../views/layouts/default.vue'),
    children: impactChildRoutes('impact', false)
  },
  {
    path: '/about',
    name: 'public.about',
    component: () => import('../views/pages/public/about.vue'),
    meta: {
      authRequired: false,
      withFooter: true,
      noHeader: false
    }
  },
  {
    path: '/giveup-giveback',
    name: 'public.giveup-giveback',
    component: () => import('../views/pages/public/giveup-giveback.vue'),
    meta: {
      authRequired: false,
      withFooter: true,
      noHeader: false
    }
  },
  {
    path: '/help-center',
    name: 'public.help-center',
    component: () => import('../views/pages/public/help-center.vue'),
    meta: {
      authRequired: false,
      withFooter: true,
      noHeader: false
    }
  },
  {
    path: '/organization',
    name: 'public.organization',
    component: () => import('../views/pages/public/organization.vue'),
    meta: {
      authRequired: false,
      withFooter: true,
      noHeader: false
    }
  },
  {
    path: '/unsubscribe',
    name: `public.unsubscribe`,
    meta: {
      title: 'Unsubscribe',
      page: 'Unsubscribe',
      authRequired: false
    },
    component: () => import('../views/pages/public/un-subscribe.vue')
  },
  {
    path: '/sms/subscribe/:token',
    name: `public.sms.subscribe`,
    meta: {
      title: 'SMS Subscribe',
      page: 'SMS Subsccribe',
      authRequired: false
    },
    component: () => import('../views/pages/public/sms-subscribe.vue'),
    props: true
  },
  {
    path: '/donation/:frequency/:cost/:subdomain',
    name: 'donation',
    beforeEnter(to) {
      window.location.href = `${import.meta.env.VITE_SCHEME}://${to.params.subdomain}.${
        import.meta.env.VITE_URI_NEW
      }/donate?amount=${to.params.cost}&smart_ask=false&selected-method=${to.params.frequency}`
    }
  },
  {
    path: '/test',
    name: 'public.social-proof',
    component: () => import('../views/pages/public/social-proof.vue')
  },
  {
    path: '/test-new',
    name: 'public.social-proof-new',
    component: () => import('../views/pages/public/social-proof-new.vue')
  },
  {
    path: '/proxy-api',
    name: 'public.embedded-api',
    component: () => import('../views/pages/public/embedded-api.vue'),
    meta: {
      authRequired: false
    }
  },
  {
    path: '/auth-check',
    name: 'public.auth-check',
    component: () => import('../views/pages/public/auth-check.vue'),
    meta: {
      authRequired: false
    }
  },
  {
    path: '/404',
    name: 'error-404',
    component: () => import('../views/pages/error/404.vue')
  },
  {
    path: '/:catchAll(.*)', // Unrecognized path automatically matches 404
    redirect: '/404'
  }
]
