const tippyOptions = {
  directive: 'tippy', // => v-tippy
  component: 'tippy', // => <tippy/>
  componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
  defaultProps: {
    placement: 'auto-end',
    allowHTML: true,
    arrow: true
  } // => Global default options * see all props
}

export default tippyOptions
