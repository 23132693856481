import * as Sentry from '@sentry/vue'
import axios from '@/utils/axios'

import { defineStore } from 'pinia'
import { useAuthStore } from './auth'
import { useNotificationStore } from './notification'
import { getSavedState, saveState } from '@/utils/helper'

export const useDonorStore = defineStore('donor', {
  state: () => ({
    donorDetail: getSavedState('donor.Detail'),
    loading: false
  }),
  getters: {
    getDonorDetail(state) {
      return state.donorDetail
    }
  },
  actions: {
    setDonorDetail(newVal) {
      if (newVal) {
        const authStore = useAuthStore()
        const claims = authStore?.getVerifiedTokenClaims || {}
        Sentry.setContext('claims', claims)
        Sentry.setUser({
          id: claims?.user_id,
          email: newVal.email,
          harness_id: claims?.harness_id
        })
      } else {
        Sentry.setUser(null)
      }

      this.donorDetail = newVal
      saveState('donor.Detail', newVal)
    },
    async fetchDonorDetails() {
      if (this.loading) return false

      const authStore = useAuthStore()
      const timestamp = Math.round(new Date().getTime() / 1000 / 5) * 5
      this.loading = true
      return await axios
        .get('/account/details', {
          params: {
            timestamp
          },
          headers: {
            'X-Harness-JWT': authStore.getToken
          }
        })
        .then((Response) => {
          this.setDonorDetail(Response.data)
          return Response.data
        })
        .catch((Error) => {
          saveState('auth.Token', null)
          const notification = useNotificationStore()
          notification.getNotificationError(Error)
          return Promise.reject(notification.getNotification.message)
        })
        .finally(() => (this.loading = false))
    }
  }
})
