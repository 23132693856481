const checkoutChildRoutes = (prop, mode = false) => [
  {
    path: '',
    name: `${prop}.order`,
    meta: {
      authRequired: mode,
      title: 'Checkout',
      withHeader: true
    },
    component: () => import('../views/pages/checkout/order.vue')
  },
  {
    path: 'order-completed/:transactionId',
    name: `${prop}.order-completed`,
    meta: {
      authRequired: mode,
      title: '',
      withHeader: true
    },
    component: () => import('../views/pages/checkout/thankyou.vue')
  },
  {
    path: 'receipt/:transactionId/:storeId',
    name: `${prop}.receipt`,
    meta: {
      authRequired: mode,
      title: '',
      withHeader: false
    },
    component: () => import('../views/pages/checkout/receipt.vue')
  }
]

export default checkoutChildRoutes
